import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import HotlinePicture from './static/hotlineit.jpg'
import MaxinetPicture from './static/maxinet.jpg'


const App = () => {
  const [dimensions, setDimensions] = useState({ 
                            height: '80vh',
                            width: '40vw'
      })
  const size = useWindowSize()

const handleResizeInternal = () => {
                  if(size.width < 700){
                  setDimensions({
                            height: '50vh',
                            width: '80vw'
                          })
                  } else {
                  setDimensions({
                            height: '50vh',
                            width: '40vw'
                          })
                  }
            }
  useEffect(() => {handleResizeInternal()}, [size])
  const useStyles = makeStyles((theme) => ({
	  root: {
		      flexGrow: 1,
		      height: dimensions.height,
          width: dimensions.width,
		    },
	  grid: {
		      flexGrow: 1,
		      height: '100vh',
		    },
	  paper: {
		      display: 'flex',
		      flexDirection: 'column',
		      alignItems: 'center',
		      justify: 'center',
		    },
	media: {
    height:'40vh',
    width:'100%',
		  },
}));

  const classes = useStyles();
    
  return (
		  <Grid container alignItems={'center'} justify={'center'} spacing={2} className={classes.grid}>
			  <Grid item>
		  		<Card className={classes.root}>
		        <CardActionArea href="https://hotlineit.com">

		          <CardMedia
		            className={classes.media}
		            image={HotlinePicture}
		            title="Holine IT"
		          />
		          <CardContent>
		            <Typography gutterBottom variant="h5" component="h2">
		              Hotline IT
		            </Typography>
		          </CardContent>
		        </CardActionArea>
		        <CardActions>
		          <Button size="small" color="primary" href="https://hotlineit.deskdirector.com/auth/v1/email">
		            Support
		          </Button>
		        </CardActions>
		      </Card>
      </Grid>
		  <Grid item>
		    <Card className={classes.root}>
		      <CardActionArea href="https://www.maxi.net.au">
		        <CardMedia
		          className={classes.media}
		          image={MaxinetPicture}
		          title="Maxinet"
		          />
		        <CardContent>
		          <Typography gutterBottom variant="h5" component="h2">
		              Maxinet
		          </Typography>
		        </CardContent>
		      </CardActionArea>
		      <CardActions>
		        <Button size="small" color="primary" href="https://www.maxi.net.au/news-n-tools-support.php">
		            Support
		        </Button>
		        <Button size="small" color="primary" href="https://webmail.maxi.net.au">
		          Webmail
		        </Button>
		      </CardActions>
		    </Card>
		  </Grid>
		</Grid>
);
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
  //   Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
     const [windowSize, setWindowSize] = useState({
           width: undefined,
           height: undefined,
           });
  
      useEffect(() => {
                     // Handler to call on window resize
      function handleResize() {
                                 // Set window width/height to state
                setWindowSize({
                               width: window.innerWidth,
                               height: window.innerHeight,
                                });
      }
                                                    
      // Add event listener
      window.addEventListener("resize", handleResize);
                                                                          
      // Call handler right away so state gets updated with initial window size
      handleResize();
                                                                                            
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
                                                                                                       }, []); // Empty array ensures that effect is only run on mount
  return windowSize;                                                                                                       }
export default App;
